.dot{
  height: 12px;
  width: 12px;
  margin-right: 1vmin;
  border-radius: 6px;
  float: left;
  margin-top: 2px;
}

.dot:hover{
  box-shadow: 4px 3px 6px -5px #212121;
  cursor: pointer;
}